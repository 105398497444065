import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DownloadApp from '../components/DownloadApp';

import theBestToolsImg from '../images/V3056060G.jpg';

import { Link } from "gatsby"

const introVideoUrl = 'https://wiseguide-static.s3.amazonaws.com/IntroVideoJeff-final.mp4';

export default () => (
  <Layout>
    <SEO title="How it Works" />

    <div>
        <div className="section image-bg-section">
          <div className="container">
            <div className="content-width-medium">
              <h6 className="subheading">How it works</h6>
              <h1 className="no-bottom-space center-mobile">The App for Gaining Confidence, Finding Happiness and Achieving Success</h1>
              <Link to="/pricing/" className="button margin-top w-inline-block">
                <div>Get started</div>
              </Link>
            </div>
          </div>
          <div className="image-background bg-image-4" />
          <div className="background-scrim-left" />
        </div>
        <div className="section bg-gray-4">
          <div className="container">
            <div className="row row-split-content direction-reverse">
              <div className="content-width-small">
                <h3 className="h1">Powerful tools targeted to your needs</h3>
                <ul className="bordered-list margin-top w-list-unstyled">
                  <li className="bordered-list-item">
                    <h6 className="h6-small">Effective, impactful programs</h6>
                    <div>The WiseGuide App contains hundreds of powerful, guided audio programs employing time-tested techniques to achieve proven positive changes.</div>
                  </li>
                  <li className="bordered-list-item">
                    <h6 className="h6-small">Targeted to your goals</h6>
                    <div>These proven techniques are applied prescriptively to the challenges you face to help you achieve the specific goals in your life.</div>
                  </li>
                  <li className="bordered-list-item bordered-list-item-last">
                    <h6 className="h6-small">Always with you</h6>
                    <div>The WiseGuide App works on any iOS or Android mobile device - from cell phones to tablets - so you can listen to your favorite programs anywhere and any time.</div>
                  </li>
                </ul>
              </div>
              <div className="content-width-large"><img src="/images/5dc99dd0c36766398759379e_square-7.jpg" width={612} srcSet="/images/5dc99dd0c36766398759379e_square-7-p-500.jpeg 500w,/images/5dc99dd0c36766398759379e_square-7-p-800.jpeg 800w,/images/5dc99dd0c36766398759379e_square-7-p-1080.jpeg 1080w,/images/5dc99dd0c36766398759379e_square-7.jpg 1226w" sizes="(max-width: 479px) 76vw, (max-width: 767px) 83vw, (max-width: 991px) 75vw, 37vw" alt="" /></div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row row-split-content direction-reverse">
              <div className="content-width-small">
                <h6 className="subheading text-primary-1">Powerful programs</h6>
                <h3 className="h1">The best tools for improving your life</h3>
                <div>The WiseGuide App puts the entire library of Effective Learning Systems’ programs at your fingertips. As the leader in the development of human potential, Effective Learning Systems programs have been the best selling and highest rated self-improvement tools for decades.They employ the most powerful techniques for life-changing impact including: mindfulness meditation, breathing techniques, guided imagery, positive emotions, affirmations, and more.</div>
                <Link to="/programs/" className="button bg-gray-4 margin-top w-inline-block">
                  <div>View programs</div>
                </Link>
              </div>
              <div className="content-width-medium"><img src={theBestToolsImg} width={506} sizes="(max-width: 479px) 76vw, (max-width: 767px) 83vw, (max-width: 991px) 506px, 37vw" alt="" /></div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row row-split-content direction-reverse">
              <div className="content-width-small">
                <h6 className="subheading text-primary-1">One million lives improved</h6>
                <h3 className="h1">Target your needs</h3>
                <div>The WiseGuide App takes the wonderful benefits of meditation to the next level. Our goal-oriented programming is prescriptively applied to address your specific needs. Lose weight, stop smoking, conquer fears, and more! We call it Productive Meditation™ All you have to do is…just listen!</div>
                <Link to="/programs/" className="button bg-gray-4 margin-top w-inline-block">
                  <div>View programs</div>
                </Link>
              </div>
              <div className="content-width-medium"><img src="/images/5ddc2c7f967a3b11943ee05f_happy%20selfie.png" width={506} srcSet="/images/5ddc2c7f967a3b11943ee05f_happy%20selfie-p-500.png 500w,/images/5ddc2c7f967a3b11943ee05f_happy%20selfie-p-800.png 800w,/images/5ddc2c7f967a3b11943ee05f_happy%20selfie.png 1012w" sizes="(max-width: 479px) 76vw, (max-width: 767px) 83vw, (max-width: 991px) 506px, 37vw" alt="" /></div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row row-split-content direction-reverse">
              <div className="content-width-small">
                <h6 className="subheading text-primary-1">Improve anywhere</h6>
                <h3 className="h1">Anywhere, Anytime, Any mood<br /></h3>
                <div>The WiseGuide App is available on your phone or tablet and even offline. And it includes programs for while you drive, sleep, exercise, or meditate. Listen anytime, anywhere!<br /></div>
                <Link to="/pricing/" className="button bg-gray-4 margin-top w-inline-block">
                  <div>Get started</div>
                </Link>
              </div>
              <div className="content-width-medium"><img src="/images/5ddc2b9f967a3b9a503ed98f_Super%20Market.png" width={506} srcSet="/images/5ddc2b9f967a3b9a503ed98f_Super%20Market-p-500.png 500w,/images/5ddc2b9f967a3b9a503ed98f_Super%20Market-p-800.png 800w,/images/5ddc2b9f967a3b9a503ed98f_Super%20Market.png 1012w" sizes="(max-width: 479px) 76vw, (max-width: 767px) 83vw, (max-width: 991px) 506px, 37vw" alt="" /></div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row row-split-content">
              <div className="content-width-small">
                <h6 className="subheading text-primary-1">One app for all life's problems</h6>
                <h3 className="h1">Immediate results</h3>
                <div>Most people experience positive results immediately and remarkable life-transformation with regular, consistent use.</div><Link to="/programs/" className="button bg-gray-4 margin-top w-inline-block">
                  <div>View programs</div>
                </Link>
              </div>
              <div className="content-width-medium"><img src="/images/5ddc2c16967a3be02c3eddcf_finish%20line.png" width={506} srcSet="/images/5ddc2c16967a3be02c3eddcf_finish%20line-p-500.png 500w,/images/5ddc2c16967a3be02c3eddcf_finish%20line-p-800.png 800w,/images/5ddc2c16967a3be02c3eddcf_finish%20line.png 1012w" sizes="(max-width: 479px) 76vw, (max-width: 767px) 83vw, (max-width: 991px) 506px, 37vw" alt="" /></div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row row-split-content">
              <div className="content-width-small">
                <h6 className="subheading text-primary-1">45 years of experience </h6>
                <h3 className="h1">Unlocking human potential </h3>
                <div>We have worked with over 1,000,000 people and over 300 organizations across the world. The WiseGuide App contains programs that have been tested and proven over the decades.</div>
                <Link to="/pricing/" className="button bg-gray-4 margin-top w-inline-block">
                  <div>Get started</div>
                </Link>
              </div>
              <div className="content-width-medium"><img src="/images/5ddc2dce8275bc947eb59b11_achivments%20.png" width={506} srcSet="/images/5ddc2dce8275bc947eb59b11_achivments%20-p-500.png 500w,/images/5ddc2dce8275bc947eb59b11_achivments%20-p-800.png 800w,/images/5ddc2dce8275bc947eb59b11_achivments%20.png 1012w" sizes="(max-width: 479px) 76vw, (max-width: 767px) 83vw, (max-width: 991px) 506px, 37vw" alt="" /></div>
            </div>
          </div>
        </div>

        <div className="section">
          <div className="container">
            <div className="row row-split-content direction-reverse">
              <div className="content-width-small">
                <h6 className="subheading text-primary-1">Easy and convenient</h6>
                <h3 className="h1">Take a look inside<br /></h3>
                <div>Watch this video for a quick tour, then download the free app and begin listening today!<br /></div>
                <Link to="/pricing/" className="button bg-gray-4 margin-top w-inline-block">
                  <div>Get started</div>
                </Link>
              </div>

              <div className="content-width-medium">
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <video controls style={{ width: '70%' }}>
                    <source src={introVideoUrl} type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>

        <DownloadApp />
      </div>
  </Layout>
);
